body {
  color: #777;
}

.pure-img-responsive {
  max-width: 100%;
  height: auto;
}

a:link, a:visited, a:hover, a:active {
  color: #000;
}

.text-btn {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

#layout, #menu, .menu-link {
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

#layout {
  padding-left: 0;
  position: relative;
  left: 0;
}

#layout.active #menu {
  width: 150px;
  left: 150px;
}

#layout.active .menu-link {
  left: 150px;
}

.content {
  max-width: 800px;
  margin: 0 auto 50px;
  padding: 0 2em;
  line-height: 1.6em;
}

.header {
  color: #333;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 2.5em 2em 0;
}

.header h1 {
  margin: .2em 0;
  font-size: 3em;
  font-weight: 300;
}

.header h2 {
  color: #ccc;
  margin-top: 0;
  padding: 0;
  font-weight: 300;
}

.content-subhead {
  color: #888;
  margin: 50px 0 20px;
  font-weight: 300;
}

#menu {
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  background: #191818;
  width: 150px;
  margin-left: -150px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

#menu a {
  color: #999;
  border: none;
  padding: .6em 0 .6em .6em;
}

#menu .pure-menu, #menu .pure-menu ul {
  background: none;
  border: none;
}

#menu .pure-menu ul, #menu .pure-menu .menu-item-divided {
  border-top: 1px solid #333;
}

#menu .pure-menu li a:hover, #menu .pure-menu li a:focus {
  background: #333;
}

#menu .pure-menu-selected, #menu .pure-menu-heading {
  background: #1f8dd6;
}

#menu .pure-menu-selected a {
  color: #fff;
}

#menu .pure-menu-heading {
  color: #fff;
  margin: 0;
  font-size: 110%;
}

.menu-link {
  z-index: 10;
  background: #000000b3;
  width: 2em;
  height: auto;
  padding: 2.1em 1.6em;
  font-size: 10px;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.menu-link:hover, .menu-link:focus {
  background: #000;
}

.menu-link span {
  display: block;
  position: relative;
}

.menu-link span, .menu-link span:before, .menu-link span:after {
  background-color: #fff;
  width: 100%;
  height: .2em;
}

.menu-link span:before, .menu-link span:after {
  content: " ";
  margin-top: -.6em;
  position: absolute;
}

.menu-link span:after {
  margin-top: .6em;
}

@media (width >= 48em) {
  .header, .content {
    padding-left: 2em;
    padding-right: 2em;
  }

  #layout {
    padding-left: 150px;
    left: 0;
  }

  #menu {
    left: 150px;
  }

  .menu-link {
    display: none;
    position: fixed;
    left: 150px;
  }

  #layout.active .menu-link {
    left: 150px;
  }
}

@media (width <= 48em) {
  #layout.active {
    position: relative;
    left: 150px;
  }
}

.stickyHeader th {
  background-color: #e0e0e0;
  border: 1px solid #cbcbcb;
  position: sticky;
  top: 0;
}
/*# sourceMappingURL=index.29005676.css.map */
